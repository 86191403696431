import { useState, useEffect, useCallback } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Card, Form, Button, Badge, ProgressBar, Toast } from "react-bootstrap";
import { Dropdown, InputGroup } from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./Home.css";

import OpenCogniton from "../services/opencognition";

import { Col } from "react-bootstrap";
import opencognition from "../services/opencognition";

import Plotly from 'plotly.js'

import lArrow from "../icons/arrow-left.svg";
import rArrow from "../icons/arrow-right.svg";

function Home() {

    var [showAlert, setShowAlert] = useState(false);
    var [alertColor, setAlertColor] = useState("green");
    var [alertHeader, setAlertHeader] = useState("");
    var [alertText, setAlertText] = useState("");

    var [text, setText] = useState();
    var [sentimentValue, setSentimentValue] = useState('Positive');
    var [sentimentProgressVariant, setSentimentProgressVariant] = useState('success'); //success, warning or danger
    var [resultColor, setResultColor] = useState("green"); //green[#28a745], #ffc107 or red 
    var [sentimentProgressValue, setSentimentProgressValue] = useState(100);



    var [listLoaded, setListLoaded] = useState(false);
    var [topicName, setTopicName] = useState("");
    var [topicDropOpen, setTopicDropOpen] = useState("false");
    var [topicList, setTopicList] = useState([]);

    var [topicListOnSearch, setTopicListOnSearch] = useState([]);

    var [requestTopicName, setRequestTopicName] = useState("");
    var [loadedTopic, setLoadedTopic] = useState();

    var [currentTweet, setCurrentTweet] = useState();
    var [currentTweetIndex, setCurrentTweetIndex] = useState(0);
    var [currentTweetSentiment, setCurrentTweetSentiment] = useState();
    var [currentTweetSentimentColor, setCurrentTweetSentimentColor] = useState();
    var [currentTweetConfidence, setCurrentTweetConfidence] = useState();

    const setInitialTweet = useCallback(()=>
    {
        if(currentTweetIndex>0)
        {
            return;
        }
        else
        {
            var csentiment = "";
            var csentimentColor = "";

            if(loadedTopic[0].sentiment===0)
            {
                csentiment="Neutral"
                csentimentColor="#ffc107"
            }
            else if(loadedTopic[0].sentiment>0)
            {
                csentiment="Positive"
                csentimentColor="green"
            }
            else
            {
                csentiment="Negative"
                csentimentColor="red"
            }

            setCurrentTweet(loadedTopic[0].text);
            setCurrentTweetSentiment(csentiment)
            setCurrentTweetSentimentColor(csentimentColor)
            setCurrentTweetConfidence(loadedTopic[0].confidence)
        }
    },[currentTweetIndex,loadedTopic]);

    const plotThePie = useCallback(()=>
    {
        if(loadedTopic!==undefined)
        {
            var gtweets = []

            var positive = 0
            var negative = 0
            var neutral = 0

            for(var i=0; i<loadedTopic.length;i++)
            {
                if(loadedTopic[i].sentiment===0)
                {
                    neutral+=1
                }
                else if(loadedTopic[i].sentiment>0)
                {
                    positive+=1
                }
                else
                {
                    negative+=1
                }
                gtweets.push(loadedTopic[i].text)
            }

            var data =[{
                values: [positive,neutral,negative],
                labels: ['Positive','Neutral','Negative'],
                name: topicName,
                hoverinfo: 'label+percent+name',
                hole: .4,
                type: 'pie'
            }]

            var layout = {
                title: 'Crowd sentiment on ~'+topicName+'~',
                annotations: [
                    {
                    font: {
                        size: 14
                    },
                    showarrow: false,
                    text: 'Tweets',
                    x: 0.5,
                    y: 0.5
                    }
                ],
                margin: {
                    t: 100
                  },
                showlegend: false,
                grid: {rows: 1, columns: 1},
                autosize: true // set autosize to rescale 
            };
            Plotly.newPlot('pieChart', data, layout,{responsive: true});
            setInitialTweet();
        }
    },[loadedTopic, setInitialTweet,topicName])

    async function getNames() {
        var tNames = await OpenCogniton.getTopicNames();

        if (tNames.status === 200) {
            setTopicList(tNames.data.topicNames.filter((e, i) => tNames.data.topicNames.indexOf(e) === i))
            setTopicListOnSearch(tNames.data.topicNames.filter((e, i) => tNames.data.topicNames.indexOf(e) === i))

            var ret = await OpenCogniton.getTopic(tNames.data.topicNames[0]);
            if (ret.status === 200) {
                setLoadedTopic(ret.data.tweets)
                setTopicName(tNames.data.topicNames[0]);
            }
            else {
                console.log("Network error!")
                console.dir(ret)
            }

        }
        else {
            setTopicList([])
        }

    }

    useEffect(() => {
        if (!listLoaded) {
            getNames();
            setListLoaded(true);
        }
        plotThePie();        
    },[listLoaded, plotThePie]);

    function setNextTweet(e)
    {
        e.preventDefault();

        var i = currentTweetIndex + 1;

        if(i>=loadedTopic.length)
        {
            return;
        }

        var csentiment = "";

        var csentimentColor = "";

        if(loadedTopic[i].sentiment===0)
        {
            csentiment="Neutral"
            csentimentColor="#ffc107"
        }
        else if(loadedTopic[i].sentiment>0)
        {
            csentiment="Positive"
            csentimentColor="green"

        }
        else
        {
            csentiment="Negative"
            csentimentColor="red"
        }

        setCurrentTweetIndex(i)
        setCurrentTweet(loadedTopic[i].text)
        setCurrentTweetSentiment(csentiment)
        setCurrentTweetSentimentColor(csentimentColor)
        setCurrentTweetConfidence(loadedTopic[i].confidence)
    }

    function setPreviousTweet()
    {
        var i = currentTweetIndex-1;

        if(i<0)
        {
            return;
        }

        var csentiment = "";

        var csentimentColor = "";

        if(loadedTopic[i].sentiment===0)
        {
            csentiment="Neutral"
            csentimentColor="#ffc107"
        }
        else if(loadedTopic[i].sentiment>0)
        {
            csentiment="Positive"
            csentimentColor="green"
        }
        else
        {
            csentiment="Negative"
            csentimentColor="red"
        }

        setCurrentTweetIndex(i)
        setCurrentTweet(loadedTopic[i].text)
        setCurrentTweetSentiment(csentiment)
        setCurrentTweetSentimentColor(csentimentColor)
        setCurrentTweetConfidence(loadedTopic[i].confidence)
    }

    async function handleTopicChange(e) {
        e.preventDefault()
        // console.log(e.target.id)
        var ret = await OpenCogniton.getTopic(e.target.id);
        if (ret.status === 200) {
            setLoadedTopic(ret.data.tweets)
            plotThePie()
        }
        else {
            console.log("Network error!")
            console.dir(ret)
        }
        setTopicName(e.target.id);
        setTopicListOnSearch(topicList.filter((e, i) => topicList.indexOf(e) === i))

    }

    function getTopicListDrops() {
        var x = topicListOnSearch.map((topic) => (
            <Dropdown.Item><div onClick={handleTopicChange} id={topic}>{topic}</div></Dropdown.Item>
        ))
        return x;
    }

    function filterDropDown(e) {
        e.preventDefault()
        if (e.target.value.length === 0) {
            setTopicListOnSearch(topicList);
            setTopicName(e.target.value);
        }
        else {
            let itemsToDisplay = [];
            itemsToDisplay = topicList.filter(
                item => item.toLowerCase().includes(e.target.value.toLowerCase()
                ));

            setTopicListOnSearch(itemsToDisplay);
            setTopicName(e.target.value);
        }
    }

    async function checkSentiment(e) {
        e.preventDefault();

        var ret = await OpenCogniton.checkSentiment(text);

        if (ret.status === 200) {
            setSentimentProgressValue(ret.data.confidence * 100)
            if (ret.data.prediction === 1) {
                setSentimentValue("Positive")
                setSentimentProgressVariant("success")
                setResultColor("green")
            }
            else if (ret.data.prediction === -1) {
                setSentimentValue("Negative")
                setSentimentProgressVariant("danger")
                setResultColor("red")
            }
            else {
                setSentimentValue("Neutral")
                setSentimentProgressVariant("warning")
                setResultColor("#ffc107")
            }
        }
        else {
            setSentimentProgressValue(100)
            setSentimentValue("Technical failure!")
            setSentimentProgressVariant("danger")
            setResultColor("red")
        }

        // console.log("Return from api :\n", ret);
    }

    async function requestTopic(e) {
        e.preventDefault();


        var ret = await opencognition.checkTopicExists(requestTopicName);

        if (ret.status === 200) {
            // console.log(ret.data)
            if (ret.data.topicExists) {
                setAlertColor("orange");
                setShowAlert(true);
                setAlertHeader("New Topic - Request error");
                setAlertText("The topic exists already. Try searching it in <change topic>.");
            }
            else {
                OpenCogniton.postNewTopic(requestTopicName);
                setAlertColor("green");
                setShowAlert(true);
                setAlertHeader("New Topic - Request Sucess");
                setAlertText("New Topic request submitted. Please check <change topic> after 10 minutes for the topic.");
            }

        }
    }

    function changeDropDown(e)
    {
        e.preventDefault();

        if(topicDropOpen==="false")
        {
            setTopicDropOpen("true")
        }
        else if(topicDropOpen==="true")
        {
            setTopicDropOpen("false")
        }
        else
        {
            setTopicDropOpen("false")
        }
    }

    var topicDrops = getTopicListDrops();
    return (

            <Container className="body-container">
                <Row className="justify-content-md-center">
                    <Col xs={12} md={4}>
                        <Card>
                            <Card.Header><h4>Check your text's sentiment</h4></Card.Header>

                            <Card.Body>
                                <Form onSubmit={checkSentiment}>
                                    <Form.Group controlId="textInput">
                                        <Form.Label><h5>Enter your text</h5></Form.Label>
                                        <Form.Control as="textarea" value={text} onChange={e => setText(e.currentTarget.value)} />
                                    </Form.Group>

                                    <Button variant="info" type="submit" size="md" >
                                        Check
                                    </Button>
                                </Form>

                                <hr />

                                <Form>
                                    <Form.Row><Form.Label><h5> Result </h5></Form.Label></Form.Row>

                                    <Form.Row >
                                        <Form.Label><h6> Sentiment : </h6></Form.Label>
                                &nbsp;&nbsp;
                                <Form.Label><h6 style={{ color: resultColor }}> {sentimentValue} </h6></Form.Label>
                                    </Form.Row>

                                    <Form.Row styling={{ padding: '0px' }}>
                                        <Form.Label><h6> Confidence : </h6></Form.Label>
                                &nbsp;&nbsp;
                                <Badge>
                                            {sentimentProgressValue}
                                        </Badge>
                                    </Form.Row>
                                    <ProgressBar variant={sentimentProgressVariant} now={sentimentProgressValue} />
                                </Form>
                            </Card.Body>

                        </Card>
                        <Toast onClose={() => setShowAlert(false)} show={showAlert} delay={6000} autohide animation>
                <Toast.Header style={{ backgroundColor: alertColor }}>
                    <strong className="mr-auto" style={{ color: "white" }}>{alertHeader}</strong>
                </Toast.Header>
                <Toast.Body>{alertText}</Toast.Body>
            </Toast>
                    </Col>

                    <Col xs={12} md={8}>
                        <Card>
                            <Card.Header>Crowd sentiment on a topic, based on tweets</Card.Header>

                            <Card.Body>
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <Form.Control placeholder="Topic Name" value={requestTopicName} onChange={e => setRequestTopicName(e.target.value)} /> &nbsp;
                                            <Button variant="info" size="md" onClick={requestTopic}>
                                                Request Topic
                                            </Button>
                                        </InputGroup >
                                    </Col>
                                    <Col>
                                        <div>
                                            <InputGroup>
                                                <Form.Control disabled placeholder="Topic Name" value={topicName} /> &nbsp;
                                            {/* <Dropdown isopen={topicDropOpen} toggle={e => setTopicDropOpen(!topicDropOpen)} > */}
                                            <Dropdown isopen={topicDropOpen} toggle={changeDropDown}>
                                                    <Dropdown.Toggle variant="info">
                                                        Change Topic
                                                </Dropdown.Toggle>
                                                    <Dropdown.Menu style={{ height: '600px', overflowY: 'scroll' }}>
                                                        <Dropdown.Header><Form.Control placeholder="Topic Name" value={topicName} onChange={filterDropDown} /></Dropdown.Header> &nbsp;
                                                    {topicDrops}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </InputGroup >
                                        </div>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col>
                                        <div id="pieChart"></div>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col>
                                        <Card>
                                            <Card.Body>
                                            <Card.Title>Tweet</Card.Title>
                                            <Card.Text>
                                                {currentTweet}
                                            </Card.Text>

                                            <Card.Title>Sentiment</Card.Title>
                                            <Card.Text>
                                                <p style={{color: currentTweetSentimentColor}}>{currentTweetSentiment}</p>
                                            </Card.Text>

                                            <Card.Title>Confidence</Card.Title>
                                            <Card.Text>
                                                {currentTweetConfidence}
                                            </Card.Text>

                                            <div style={{textAlign:'center'}}>
                                                <Button style={{float:'left'}} variant="primary" onClick={setPreviousTweet}><img  alt="left arrow" style={{color:'white'}} src={lArrow}/>&nbsp;Previous Tweet</Button>
                                                <Button style={{float:'right'}}  variant="primary" onClick={setNextTweet}>Next Tweet&nbsp;<img  alt="right arrow" style={{color:'white'}} src={rArrow}/></Button>
                                            </div>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

    );
}

export default Home;
