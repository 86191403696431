import { Card, Container } from "react-bootstrap";
import Banner from "../icons/SentimentStack.png";


function About() {

    return (
        <Container className="body-container">
            <Card>
                <Card.Header><h4 className="header">About the project</h4></Card.Header>

                <Card.Body>
                    <p className="lead">
                        <ul>
                            <li>A small sentiment analyzer, which can detect the sentiment of a text, however long it is.</li>
                            <li>The service is built as a Azure Function micro-service. </li>
                            <li>The documentation pages, however uses Twitter API too, to capture tweets and find the crowd sentiment on a Topic.</li>

                            <li>The source code and model for the sentiment analyzer service can be found in this repository,
                        <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://github.com/rangakamesh/SentimentAnalyzer" target='none'>github.com/rangakamesh/SentimentAnalyzer</a>
                            </li>
                        </ul>

                        <div class="container-sm" style={{ maxHeight: '1000px', maxWidth: '500px' }}>
                            <img src={Banner} class="img-fluid" alt="Sentiment Analyzer Architecture" />
                        </div>
                    </p>
                </Card.Body>

            </Card>
            <br />
            <Card>
                <Card.Header><h4 className="header">Machine Learning Model</h4></Card.Header>

                <Card.Body>
                    <p className="lead">
                        <ul>
                            <li>The ML Model was built using ML.NET package in C#. It is a Logistic Regression model.</li>
                            <li>Three instances of the model are trained with three different datasets.</li>
                            <li>A voting classifier then, finds the best result based on the output from all the instances.</li>
                            <li>Dataset used : <a href="https://archive.ics.uci.edu/ml/datasets/Sentiment+Labelled+Sentences" target="none">Sentiment Labelled Sentences Dataset</a> by UCI Machine Learning Repository.</li>
                        </ul>
                    </p>
                </Card.Body>

            </Card>
            <br />
            <Card>
                <Card.Body>
                    <p className="lead">
                        For help on consuming the pre-hosted service, please refer to the documentation tab.
                </p>
                </Card.Body>

            </Card>
        </Container>
    );

}

export default About;
