import axios from 'axios';
import qs from 'qs';

var OpenCognitionURL = "https://opencognition.azurewebsites.net/";

class OpenCogniton
{
    checkSentiment(text)
    {
        return axios.get(OpenCognitionURL+"PredictSentiment?text="+qs.stringify({text}))
    }

    getTopicNames()
    {
        return axios.get(OpenCognitionURL+"GetTopicNames")
    }

    checkTopicExists(topicname)
    {
        return axios.get(OpenCognitionURL+"CheckTopicExists?"+qs.stringify({topicname}))
    }

    postNewTopic(topic)
    {
        return axios.post("https://us-south.functions.appdomain.cloud/api/v1/web/rangakamesh%40hotmail.com_dev/default/collectTweets",{
            topic
        })
    }

    getTopic(topicName)
    {
        return axios.get(OpenCognitionURL+"GetTopic?"+qs.stringify({topicName}));
    }

}


export default new OpenCogniton();
