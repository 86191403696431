import { Card, Container } from "react-bootstrap";

function About() {

    return (
        <Container className="body-container">
            <Card>
                <Card.Header><h4 className="header">How to consume the service</h4></Card.Header>

                <Card.Body>
                    <p className="lead">
                        <ul>
                            <li>Reach out to this service at <a href="https://opencognition.azurewebsites.net/PredictSentiment" target="none">https://opencognition.azurewebsites.net/PredictSentiment</a></li>
                            <li>The service responds to a GET request with the input parameter "Text".</li>
                            <li>The "Text" parameter should contain the text to be analyzed.</li>
                            <li>For example, <a href="https://opencognition.azurewebsites.net/PredictSentiment?Text=this is not a bad sentence" target="none">opencognition.azurewebsites.net/PredictSentiment?Text=this is not a bad sentence</a>
                            </li>
                            <li>Encode the input text with a suitable URL encoder, if you are reaching out to the endpoint from a program.</li>                                    
                            <ul><li>This makes sure that any special characters are transferred over, successfully.</li></ul>
                        </ul>
                    </p>
                </Card.Body>

            </Card>
        </Container>
    );

}

export default About;
