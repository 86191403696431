import {useState} from 'react';

import './App.css';

import Home from './components/Home';
import About from './components/About';
import Documentation from './components/Documentation';

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

function App() {

  var [page,setPage] = useState(<Home/>);

  function changePage(e)
  {
    e.preventDefault();

    if(e.target.id==="home")
    {
      setPage(<Home/>);
    }
    else if(e.target.id==="about")
    {
      setPage(<About/>);
    }
    else if(e.target.id==="documentation")
    {
      setPage(<Documentation/>);
    }
    else
    {
      setPage(<Home/>);
    }
  }

  return (
    <Container fluid className="home-container">
      <Navbar variant="dark" bg="info" style={{ padding: "10px" }}>
        <Navbar.Brand href="/">Setimentizer</Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link href="#" id="home" onClick={changePage} style={{ color: "white" }}>Home</Nav.Link>
          <Nav.Link href="#" id="about" onClick={changePage} style={{ color: "white" }}>About</Nav.Link>
          <Nav.Link href="#" id="documentation" onClick={changePage} style={{ color: "white" }}>Documentation</Nav.Link>
        </Nav>
      </Navbar>

      {/* <Router>
        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/documentation" component={Documentation} />
      </Router> */}

      {page}

    </Container>
  );
}

export default App;
